import { ThemeProvider } from '@emotion/react';
import { Route, Switch } from 'react-router-dom';
import { theme } from '../theme';
import { AppHome } from './AppHome';
import { TenantHome } from './pages/tenant-home/TenantHome';

export const App = () => (
  <ThemeProvider theme={theme}>
    <Switch>
      <Route component={TenantHome} path="/:tenantId" />
      <Route component={AppHome} exact={true} path="/" />
    </Switch>
  </ThemeProvider>
);

export default App;
