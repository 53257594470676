import { H1 } from '@risk-first/ui-core';
import { App as Main, AppHeader } from './AppHome.style';
import { TenantChooser } from './components/tenant-chooser/TenantChooser';
import { useSelectedApp } from './hooks/use-selected-app';

export const AppHome = () => {
  const selecetedApp = useSelectedApp();

  return (
    <>
      <Main>
        <AppHeader>
          <H1>{selecetedApp?.name ?? 'Unknown'}</H1>
          <p style={{ margin: '1em 0' }}>
            Here we will have some mechanism to determine what tenant the user wants to access. For now, we will just
            use a dropdown.
          </p>
          <TenantChooser />
        </AppHeader>
      </Main>
    </>
  );
};
