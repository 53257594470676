import { useLocation } from 'react-router-dom';
import { apps } from '../data/apps';
import { isLocalhost } from '../utils/is-localhost';

export const useSelectedApp = () => {
  const { search } = useLocation();
  let appId: string;

  if (isLocalhost()) {
    const regex = /[?&]app=([^&]*)/;

    if (regex.test(search)) {
      appId = search.match(regex)![1];
    }
  } else {
    appId = document.location.host.split('.')[0];
  }

  return apps.find((app) => app.id === appId);
};
