import { Dropdown, Option } from '@risk-first/ui-dropdown';
import { tenants } from '../../data/tenants';
import { useSelectedTenant } from '../../hooks/use-selected-tenant';

export const TenantChooser: React.FC = () => {
  const selectedTenant = useSelectedTenant();

  return (
    <Dropdown
      label={selectedTenant?.name ?? 'Select a tenant'}
      value={selectedTenant?.id}
      variant="select"
      onChange={(selectedTenant: string) => {
        window.location.pathname = `/${selectedTenant}`;
      }}
    >
      {tenants.map((tenant, index) => (
        <Option key={tenant.id} index={index} value={tenant.id}>
          {tenant.name}
        </Option>
      ))}
    </Dropdown>
  );
};
